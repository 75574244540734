<template>
  <HeaderBlock></HeaderBlock>
  <div class="container">

    <MainView/>

    <FooterBlock></FooterBlock>
  </div>

</template>

<script>
import 'bootstrap'
import MainView from "@/views/MainView";
import HeaderBlock from "@/components/HeaderBlock";
import FooterBlock from "@/components/FooterBlock";
export default {
  name: 'App',
  components: {
    FooterBlock,
    HeaderBlock,
    MainView,
  }
}
</script>

<style lang="css" src="./css/pm.css"></style>
<style lang="scss" src="./scss/portal.scss"></style>
