<template>
  <footer id="footer">
    <div class="row">
      <div class="col-lg-12">
        <ul class="list-unstyled">
          <li class="float-end"><a href="#top">Наверх</a></li>
        </ul>

      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterBlock",
}
</script>

<style scoped>

</style>