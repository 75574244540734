<template>

  <!--  <div class="page-header">
      <div class="row">
        <div class="col-lg-8 col-md-7 col-sm-6">
          <h1>Solar</h1>
          <p class="lead">A spin on Solarized</p>
        </div>
        <div class="col-lg-4 col-md-5 col-sm-6">
          <div class="sponsor">
          </div>
        </div>
      </div>
    </div>-->

  <a id="a-benefits"></a>

  <div class="row fs20">
    <div class="row mt30">
      <div class="col-12">
        <div class="list-group">
          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start  pv20 ph40">
            <div class="d-flex w-100 justify-content-between">
              <h2 class="mb-3 text-success">Что такое ТСЖ?</h2>
            </div>
            <p>Товарищество собственников жилья (ТСЖ) — это некоммерческое объединение собственников квартир и нежилых
              помещений в многоквартирном доме для совместного управления общим имуществом.</p>
          </a>
          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start active pv20 ph40">
            <div class="d-flex w-100 justify-content-between">
              <h2 class="mb-3 text-success">Цели</h2>
            </div>
            <p>Задача ТСЖ – содержать, сохранять и приумножать общее имущество, обеспечивать жильцам поставки коммунальных
              ресурсов.</p>
            <p>Собственной материально-технической базы ТСЖ не имеет, поэтому заключает договоры с подрядными организациями,
              которые обслуживают дом.</p>
          </a>
          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start  pv20 ph40">
            <div class="d-flex w-100 justify-content-between">
              <h2 class="mb-3 text-success">Преимущества</h2>
            </div>
            <p>ТСЖ заинтересовано в снижении затрат на ресурсы, поскольку его участники являются собственниками жилья в
              доме.</p>
            <p>Сэкономленные деньги идут на благоустройство и другие удобства.</p>
          </a>
          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start active pv20 ph40">
            <div class="d-flex w-100 justify-content-between">
              <h2 class="mb-3 text-success">Возможности</h2>
            </div>
            <p>ТСЖ имеет право участвовать в конкурсах на соискание грантов и субсидий на благоустройство придомовой и
              внутридомовой территории, может обращаться за материальной поддержкой к депутатам и в фонды.</p>
          </a>
          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start  pv20 ph40">
            <div class="d-flex w-100 justify-content-between">
              <h2 class="mb-3 text-success">Регламент</h2>
            </div>
            <p>Общим собранием собственников утверждается устав, избираются члены правления (на период не более двух лет) и
              контролирующая ревизионная комиссия.</p>
            <p>Финансовая деятельность организации более прозрачна, чем в УК. ТСЖ имеет
              печать, счета в банке и создается на неопределенный срок.</p>
          </a>
        </div>
      </div>
    </div>

    <a id="a-persons"></a>
    <div class="row mt70">
      <h1>Кандидаты в члены правления ТСЖ</h1>

      <div class="col-12 mt20">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Балдаков Г.А.</h3>
            <h4 class="card-subtitle mb-2 text-muted">(кв. 98)</h4>
            <h6 class="card-subtitle mb-2 text-muted">1990 г.р., высшее образование, старший инженер.</h6>
          </div>
        </div>
        <div class="card bg-dark">
          <div class="card-body">
            <h3 class="card-title">Бушин А.В.</h3>
            <h4 class="card-subtitle mb-2 text-muted">(кв. 48)</h4>
            <h6 class="card-subtitle mb-2 text-muted">1961 г.р., высшее образование, пенсионер.</h6>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Габайдулина Ю.А.</h3>
            <h4 class="card-subtitle mb-2 text-muted">(кв. 252)</h4>
            <h6 class="card-subtitle mb-2 text-muted">1985 г.р., высшее образование, руководитель.</h6>
          </div>
        </div>
        <div class="card bg-dark">
          <div class="card-body">
            <h3 class="card-title">Киселева Н.Н.</h3>
            <h4 class="card-subtitle mb-2 text-muted">(кв. 357)</h4>
            <h6 class="card-subtitle mb-2 text-muted">1984 г.р., высшее образование, экономист.</h6>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Круглова В.В.</h3>
            <h4 class="card-subtitle mb-2 text-muted">(кв. 287)</h4>
            <h6 class="card-subtitle mb-2 text-muted">1990 г.р., высшее образование, предприниматель.</h6>
          </div>
        </div>
        <div class="card bg-dark">
          <div class="card-body">
            <h3 class="card-title">Ларин И.А.</h3>
            <h4 class="card-subtitle mb-2 text-muted">(кв. 161)</h4>
            <h6 class="card-subtitle mb-2 text-muted">1990 г.р., высшее образование, ИП, инженер автоматизации.</h6>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Михеенко А. А.</h3>
            <h4 class="card-subtitle mb-2 text-muted">(кв. 343)</h4>
            <h6 class="card-subtitle mb-2 text-muted">1977 г.р., высшее образование, руководитель.</h6>
          </div>
        </div>
        <div class="card bg-dark">
          <div class="card-body">
            <h3 class="card-title">Седова В.Р.</h3>
            <h4 class="card-subtitle mb-2 text-muted">(кв. 222)</h4>
            <h6 class="card-subtitle mb-2 text-muted">1987 г.р., Председатель совета ТОС "Глинищево", высшее образование, преподаватель, переводчик.</h6>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Чахнин А.В.</h3>
            <h4 class="card-subtitle mb-2 text-muted">(кв. 338)</h4>
            <h6 class="card-subtitle mb-2 text-muted">1970 г.р., высшее образование, юрист.</h6>
          </div>
        </div>

      </div>
    </div>

    <div class="row mt70" id="a-docs">
      <h1>Предварительная документация</h1>
      <div class="col-12 mt20">
        <div class="card bg-info bg-opacity-10">
          <div class="card-body row">
            <div class="col-xs-12 col-md-6 text-center p20">
              <a href="/ustav-pk41.docx">
                <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" fill="currentColor" class="bi bi-file-earmark-text-fill" viewBox="0 0 16 16">
                  <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1z"/>
                </svg>
                <div class="mt15">
                  <h3>Устав ТСЖ ПК41</h3>
                </div>
              </a>
            </div>
            <div class="col-xs-12 col-md-6 text-center p20">
              <a href="/smeta-pk41.xlsx">
                <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" fill="currentColor" class="bi bi-file-earmark-text-fill" viewBox="0 0 16 16">
                  <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1z"/>
                </svg>
                <div class="mt15">
                  <h3>Смета ТСЖ ПК41</h3>
                </div>
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row mt70" id="a-upr">
      <h1>Кандидат на должность управляющего ТСЖ</h1>
      <div class="col-12 mt20">
        <div class="row g-0 card overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
          <div class="col-md-4  d-lg-block">
            <img src="../assets/k.jpg" class="img-fluid"  alt="Копылова Екатерина Николаевна"/>
          </div>
          <div class="col p-4 d-flex flex-column position-static">
            <h1 class="mb-0 text-success">Копылова Екатерина Николаевна</h1>
            <div class="mb-4 text-body-secondary">1983 г.р.</div>

            <p>
              Средне-специальное образование: специальность «Маркетинг в машиностроении», Машиностроительный колледж, красный диплом.
            </p>
            <p>
              Высшее образование: специальность «Маркетинг в текстильной промышленности», Текстильная Академия.
            </p>

            <ul class="list-group mb30">
              <li class="list-group-item d-flex justify-content-between align-items-center active">
                С 2017 года председатель ТСЖ "Рождественский-8".
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center ">
                С 2021 года управляющий ТСЖ "Рождественский-10".
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center active">
                С 2017 года председатель ТОС "Рождественский".
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center ">
                С 2018 года по 2020 год - председатель комиссии по ЖКХ, экологии и строительству в Общественной палате Ивановской области.
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center active">
                С 2019 по 2021 год - сопредседатель Народного фронта в Ивановской области.
              </li>
            </ul>

            <p class="text-info ml5">Сейчас является членом регионального штаба Народного фронта.<br>
            Эксперт в сфере ЖКХ.<br>
            С 2020 года - Депутат городской думы 7 созыва по 5 одномандатному округу.</p>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {}
</script>

<style scoped>

</style>